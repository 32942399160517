.Global {
  max-width: 100%;
}
a {
  text-decoration: none;
  display: inline-block;
}
*{
  outline: none;
}
.hidden {
  display: none;
}
html, body, div, form, fieldset, legend, label {
  margin: 0;
  padding: 0;
}

@media (max-width:768px) {
  body {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    position: relative;
    letter-spacing: normal;
    color: #595959;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
  }
}

@media (min-width:768px) {
  body {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    position: relative;
    letter-spacing: normal;
    color: #595959;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
  }
}

@media (min-width:1200px) {
  body {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    position: relative;
    letter-spacing: normal;
    color: #595959;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
  }
}